import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['message', 'text']
  static classes = []
  static values = {
    text: String,
    succeeded: { type: String, default: 'コピーしました' },
  }

  copyText() {
    navigator.clipboard.writeText(this.textValue)
    const originalText = this.messageTarget.innerHTML
    this.messageTarget.innerHTML = this.succeededValue
    setTimeout(() => {
      this.messageTarget.innerHTML = originalText
    }, 1200)
  }

  copyInnerText() {
    navigator.clipboard.writeText(this.textTarget.innerText)
    const originalText = this.messageTarget.innerHTML
    this.messageTarget.innerHTML = this.succeededValue
    setTimeout(() => {
      this.messageTarget.innerHTML = originalText
    }, 1200)
  }
}
