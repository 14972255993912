import { Controller } from 'stimulus'
import axios from 'axios'
import Rails from '@rails/ujs'

axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken()

export default class extends Controller {
  static values = {
    sku: String,
  }

  static targets = ['button']

  isProcessing = false

  async addToCart(event) {
    event.preventDefault()
    if (this.isProcessing) return

    this.isProcessing = true
    this.disableButton()

    try {
      await this.updateCartQuantity(1)
      // 成功時にはボタンを再有効化しない（すでに別の状態に変わっているため）
    } catch (error) {
      this.handleError(error)
      this.enableButton() // エラー時のみボタンを再有効化
    } finally {
      this.isProcessing = false
    }
  }

  async updateQuantity(event) {
    const newQuantity = parseInt(event.target.value)
    await this.updateCartQuantity(newQuantity)
  }

  async updateCartQuantity(quantity) {
    const response = await axios.post('/api/web/cart_modifier', {
      SKU: this.skuValue,
      QTY: quantity,
    })

    if (response.status === 200) {
      const data = response.data
      await this.updateButtonState(data.qty)
      this.updateCartSummary(data)
    } else {
      throw new Error('Failed to update cart')
    }
  }

  async updateButtonState(quantity) {
    const response = await axios.get(
      `/api/web/cart_button_states/${this.skuValue}`,
      {
        params: { quantity, sku: this.skuValue },
      },
    )

    if (response.status === 200) {
      const buttons = document.querySelectorAll(`[name="qty_${this.skuValue}"]`)
      buttons.forEach(button => {
        const parentElement = button.closest('.cart-button')
        if (parentElement) {
          parentElement.outerHTML = response.data
        }
      })
    } else {
      throw new Error('Failed to fetch button state')
    }
  }

  updateCartSummary(data) {
    this.updateElement('cart_qtysum', data.qtysum)
    this.updateElement('cart_subtotal', this.formatNumber(data.basic))
    this.toggleElement('cart_display', data.qtysum !== 0)
  }

  updateElement(id, value) {
    const element = document.getElementById(id)
    if (element) element.textContent = value
  }

  toggleElement(id, show) {
    const element = document.getElementById(id)
    if (element) element.classList.toggle('d-none', !show)
  }

  formatNumber(number) {
    return new Intl.NumberFormat('ja-JP').format(number)
  }

  handleError(error) {
    if (error.message === 'Failed to update cart') {
      alert('カートの更新に失敗しました。')
    } else {
      window.location.reload()
    }
  }

  disableButton() {
    if (this.hasbuttonTarget) {
      this.buttonTarget.disabled = true
      this.buttonTarget.classList.add('opacity-50', 'cursor-not-allowed')
    }
  }

  enableButton() {
    if (this.hasbuttonTarget) {
      this.buttonTarget.disabled = false
      this.buttonTarget.classList.remove('opacity-50', 'cursor-not-allowed')
    }
  }
}
