import { Controller } from 'stimulus'
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'

export default class extends Controller {
  connect() {
    const bannerSlideLength =
      this.element.querySelectorAll('.swiper-slide').length

    // バナー画像枚数が1枚以下ならswiper初期化しない
    if (bannerSlideLength > 1) {
      this.swiper = new Swiper(this.element.querySelector('.swiper'), {
        modules: [Autoplay],
        loop: true,
        loopAdditionalSlides: 1,
        slidesPerView: 'auto',
        spaceBetween: 0,
        threshold: 10,
        centeredSlides: true,
        grabCursor: true,
        speed: 500,
        autoplay: {
          delay: 7000,
        },
      })
    }
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
