import { Controller } from 'stimulus'
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(this.element.querySelector('.swiper'), {
      modules: [Navigation, Pagination],
      loop: false,
      loopAdditionalSlides: 1, // ループモード時に複製するスライド数を指定
      slidesPerView: 1,
      spaceBetween: 5,
      centeredSlides: true,
      grabCursor: true,
      speed: 100,
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        clickable: true,
      },
    })
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
