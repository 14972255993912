import { Controller } from 'stimulus'
import Swiper from 'swiper'
import { Navigation, Mousewheel, FreeMode } from 'swiper/modules'

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(this.element.querySelector('.swiper'), {
      modules: [Navigation, Mousewheel, FreeMode],
      slidesPerView: 'auto',
      spaceBetween: 3,
      threshold: 10,
      grabCursor: true,
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
      freeMode: {
        enabled: true,
        sticky: true,
        momentumRatio: 0.3,
        momentumVelocityRatio: 0.35,
      },
    })
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
