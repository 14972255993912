import { Controller } from 'stimulus'
import { Modal, Toast } from 'bootstrap'
import axios from 'axios'
import Rails from '@rails/ujs'

axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken()

export default class extends Controller {
  static values = {
    klass: String,
    id: Number,
  }
  static targets = ['button']

  isProcessing = false
  static visitorModal = null

  connect() {
    if (!this.constructor.visitorMyfeedsModal) {
      this.createVisitorMyfeedsModal()
    }
  }

  async toggle(event) {
    event.preventDefault()
    if (this.isProcessing) return

    this.isProcessing = true
    this.disableButton()

    try {
      const state = await this.toggleMyfeedsState()
      if (state) {
        await this.updateButtonState()
        this.showToast(state)
      } else {
        this.constructor.visitorMyfeedsModal.show()
        this.enableButton()
      }
    } catch (error) {
      this.handleError(error)
      // エラーのときはボタン状態を復帰する(たまたま通信エラー、の可能性があるため)
      this.enableButton()
    } finally {
      this.isProcessing = false
    }
  }

  async toggleMyfeedsState() {
    try {
      const response = await axios.post('/api/web/myfeeds_toggler', {
        klass: this.klassValue,
        id: this.idValue,
      })
      return response.data
    } catch (error) {
      throw new Error('Failed to update Myfeeds state')
    }
  }

  async updateButtonState() {
    try {
      const strStyles = ['tag', 'state', 'minimal']
      for (const style of strStyles) {
        const buttons = document.querySelectorAll(
          `[name="myfeeds-${this.klassValue}-${this.idValue}-${style}"]`,
        )
        if (buttons.length !== 0) {
          const response = await axios.get(
            `/api/web/myfeeds_button_states/${this.idValue}`,
            {
              params: {
                klass: this.klassValue,
                style,
              },
            },
          )
          buttons.forEach(button => {
            const parentElement = button.closest('.myfeeds-button')
            if (parentElement) {
              parentElement.outerHTML = response.data
            }
          })
        }
      }
    } catch {
      throw new Error('Failed to fetch button state')
    }
  }

  showToast(state) {
    const toastContainer = this.getOrCreateToastContainer()
    const toastElement = this.createToastElement(state)
    toastContainer.appendChild(toastElement)

    const toast = new Toast(toastElement, {
      animation: true,
      autohide: true,
      delay: 3000,
    })

    toast.show()

    toastElement.addEventListener('hidden.bs.toast', () => {
      toastElement.remove()
    })
  }

  getOrCreateToastContainer() {
    let container = document.getElementById('toast-container')
    if (!container) {
      container = document.createElement('div')
      container.id = 'toast-container'
      container.className = 'position-fixed top-0 end-0 m-3'
      container.style.zIndex = '1200'
      document.body.appendChild(container)
    }
    return container
  }

  createToastElement(state) {
    const { registered, tagword } = state
    const message = registered
      ? `よりどり続報に「${tagword}」を追加しました。`
      : `「${tagword}」の続報受信を解除しました。`

    const toastElement = document.createElement('div')
    const colorTheme = registered ? 'info' : 'danger'
    toastElement.className = `toast mb-1`
    toastElement.ariaLive = 'assertive'
    toastElement.ariaAtomic = 'true'
    toastElement.innerHTML = `
      <div class="toast-header text-bg-${colorTheme} py-0">
        <span class="head5 fw-bold">よりどり続報</span>
        <a href="${window.Routes.myfeeds_guidance_url}" class="btn btn-outline-linkage btn-bg-white btn-sm px-3 py-0 ms-2 me-auto">案内ページ</a>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-${colorTheme}-emphasis bg-${colorTheme}-subtle fs-6">${message}</div>
    `

    return toastElement
  }

  createVisitorMyfeedsModal() {
    if (document.getElementById('visitorMyfeedsModal')) return

    const modalElement = document.createElement('div')
    modalElement.innerHTML = `
      <div class="modal" id="visitorMyfeedsModal" tabindex="-1" aria-labelledby="visitorMyfeedsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="visitorMyfeedsModalLabel">会員登録とログインが必要です</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>あらかじめ商品やイベントなどを選んでおくと、それらの新情報が発生したときだけでお伝えする、スマートで便利な「<strong>よりどり続報</strong>」を利用するには、会員登録とログインが必要です。
                <a href="${window.Routes.myfeeds_guidance_url}" class="btn btn-sm btn-inline btn-outline-linkage">くわしい案内</a>
              </p>
            </div>
            <div class="modal-footer justify-content-center">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">もどる</button>
              <a href="${window.Routes.login_url}" class="btn btn-primary">会員登録/ログイン</a>
            </div>
          </div>
        </div>
      </div>
    `
    document.body.appendChild(modalElement)

    this.constructor.visitorMyfeedsModal = new Modal(
      document.getElementById('visitorMyfeedsModal'),
      {
        backdrop: true,
        keyboard: true,
      },
    )
  }

  handleError(error) {
    if (error.message === 'Failed to update Myfeeds state') {
      alert('よりどり続報の設定変更ができませんでした。')
    } else {
      // 設定トグルはうまくいったとして、再描画での問題などはリロードで対応する
      window.location.reload()
    }
  }

  disableButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = true
      this.buttonTarget.classList.add('opacity-50', 'cursor-not-allowed')
    }
  }

  enableButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = false
      this.buttonTarget.classList.remove('opacity-50', 'cursor-not-allowed')
    }
  }
}
