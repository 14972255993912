import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['rejectButton', 'examinationComment']

  rejectDueToManyWarnings() {
    this.dispatch('rejectDueToManyWarnings')
    this.examinationCommentTarget.value = '指摘箇所過多'
    this.rejectButtonTarget.click()
  }
}
