import { ApplicationController } from '../../application_controller'

const STATE = {
  UNDER_EXAMINATION: 'under_examination',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  REJECTED_WITH_REMARK: 'rejected_with_remark',
  EDIT_REMARK: 'edit_remark',
}

export default class extends ApplicationController {
  static states = STATE
  static targets = ['statusField', 'remarkField', 'remark', 'remarkTextArea']
  static values = {
    state: { type: String, default: 'under_examination' },
    status: String,
    remark: String,
    line: Number,
    selected: { type: Boolean, default: false },
  }

  connect() {
    this.remarkTextAreaTarget.value = this.remarkValue
    switch (this.statusValue) {
      case 'approved':
        this.approve()
        break
      case 'rejected':
        if (this.remarkValue && this.remarkValue !== '') {
          this.rejectWithRemark()
        } else {
          this.reject()
        }
        break
    }
  }

  selectedValueChanged(value) {
    this.element.classList.toggle('bg-danger-subtle', value)
  }

  statusValueChanged(value) {
    this.statusFieldTarget.value = value
  }

  remarkValueChanged(value) {
    this.remarkFieldTarget.value = value
    this.remarkTarget.innerText = value
  }

  approve() {
    this.stateValue = STATE.APPROVED
    this.statusValue = 'approved'
    this.remarkValue = undefined
  }

  reject() {
    this.stateValue = STATE.REJECTED
    this.statusValue = 'rejected'
    this.remarkValue = undefined
  }

  editRemark() {
    this.stateValue = STATE.EDIT_REMARK
    this.remarkTextAreaTarget.focus()
  }

  cancelRemark() {
    this.stateValue = STATE.UNDER_EXAMINATION
  }

  rejectWithRemark() {
    this.stateValue = STATE.REJECTED_WITH_REMARK
    this.statusValue = 'rejected'
    this.remarkValue = this.remarkTextAreaTarget.value
  }

  redo() {
    this.stateValue = STATE.UNDER_EXAMINATION
    this.statusValue = 'under_examination'
    this.remarkValue = undefined
  }

  // その他の指摘事項の場合、コメントが入力されていた場合にrejectステータスにする
  checkForOtherExamination() {
    if (this.remarkTextAreaTarget.value === '') {
      this.redo()
    } else {
      this.rejectWithRemark()
    }
  }

  rejectDueToManyWarnings() {
    if (this.stateValue !== STATE.UNDER_EXAMINATION) {
      return
    }
    this.remarkFieldTarget.value = ''
    this.statusFieldTarget.value = 'rejected'
    this.reject()
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  toggleHighlight() {
    if (!this.lineValue) return
    if (this.selectedValue) {
      this.dispatch('highlight', { detail: { line: null } })
    } else {
      this.dispatch('highlight', { detail: { line: this.lineValue } })
    }
  }

  toggleSelected({ detail: { line } }) {
    this.selectedValue = line === this.lineValue
  }
}
