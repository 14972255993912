import { Controller } from 'stimulus'
import { ApiClient } from '../utils/ApiClient'

export default class extends Controller {
  static targets = ['closeButton', 'content']

  static values = {
    url: String,
  }

  async report() {
    const content = this.contentTarget.value
    if (!content) {
      alert('報告内容を入力してください。')
      return
    }

    try {
      await ApiClient.post(`/api/web/issue_reports`, {
        content: content,
        url: this.urlValue,
      })
      alert('送信に成功しました。')
      this.closeButtonTarget.click()
    } catch (error) {
      alert('送信に失敗しました。時間をおいて再度お試しください。')
    }
  }
}
