import { Controller } from 'stimulus'
import { Modal, Toast } from 'bootstrap'
import axios from 'axios'
import Rails from '@rails/ujs'

axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken()

export default class extends Controller {
  static values = {
    itemId: Number,
  }
  static targets = ['button']

  isProcessing = false
  static visitorModal = null

  connect() {
    if (!this.constructor.visitorBookmarkModal) {
      this.createVisitorBookmarkModal()
    }
  }

  async toggle(event) {
    event.preventDefault()
    if (this.isProcessing) return

    this.isProcessing = true
    this.disableButton()

    try {
      const state = await this.toggleBookmarkState()
      if (state) {
        this.showToast(state)
        await this.updateButtonState()
        // ブックマーク状態が変更されたことを通知
        this.dispatchBookmarkChangedEvent()
      } else {
        this.constructor.visitorBookmarkModal.show()
        this.enableButton()
      }
    } catch (error) {
      this.handleError(error)
      // エラーのときはボタン状態を復帰する(たまたま通信エラー、の可能性があるため)
      this.enableButton()
    } finally {
      this.isProcessing = false
    }
  }

  async toggleBookmarkState() {
    try {
      const response = await axios.post('/api/web/bookmark_toggler', {
        item_id: this.itemIdValue,
      })
      return response.data
    } catch (error) {
      throw new Error('Failed to update Bookmark state')
    }
  }

  async updateButtonState() {
    try {
      const response = await axios.get(
        `/api/web/bookmark_button_states/${this.itemIdValue}`,
        {},
      )
      const buttons = document.querySelectorAll(
        `[name="bookmark-${this.itemIdValue}"]`,
      )
      buttons.forEach(button => {
        const parentElement = button.closest('.bookmark-button')
        if (parentElement) {
          parentElement.outerHTML = response.data
        }
      })
    } catch {
      throw new Error('Failed to fetch button state')
    }
  }

  showToast(state) {
    const toastContainer = this.getOrCreateToastContainer()
    const toastElement = this.createToastElement(state)
    toastContainer.appendChild(toastElement)

    const toast = new Toast(toastElement, {
      animation: true,
      autohide: true,
      delay: 3000,
    })

    toast.show()

    toastElement.addEventListener('hidden.bs.toast', () => {
      toastElement.remove()
    })
  }

  getOrCreateToastContainer() {
    let container = document.getElementById('toast-container')
    if (!container) {
      container = document.createElement('div')
      container.id = 'toast-container'
      container.className = 'position-fixed top-0 end-0 m-3'
      container.style.zIndex = '1200'
      document.body.appendChild(container)
    }
    return container
  }

  createToastElement(state) {
    const { resultMarked, itemName } = state
    const message = resultMarked
      ? `「${itemName}」をマークしました`
      : `「${itemName}」のマークを外しました`

    const toastElement = document.createElement('div')
    const colorTheme = resultMarked ? 'info' : 'danger'
    toastElement.className = `toast mb-1`
    toastElement.ariaLive = 'assertive'
    toastElement.ariaAtomic = 'true'
    toastElement.innerHTML = `
      <div class="toast-header text-bg-${colorTheme} py-0">
        <span class="head5 fw-bold">マーク機能</span>
        <a href="${window.Routes.marked_list_url}" class="btn btn-outline-linkage btn-bg-white btn-sm px-2 py-0 ms-2 me-auto">マーク商品リスト</a>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-${colorTheme}-emphasis bg-${colorTheme}-subtle fs-6">${message}</div>
    `

    return toastElement
  }

  createVisitorBookmarkModal() {
    if (document.getElementById('visitorBookmarkModal')) return

    const modalElement = document.createElement('div')
    modalElement.innerHTML = `
      <div class="modal" id="visitorBookmarkModal" tabindex="-1" aria-labelledby="visitorBookmarkModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="visitorBookmarkModalLabel">会員登録とログインが必要です</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>気になるゲームをどんどんマークし、マークした品物だけの一覧を見ながら、購入するゲームを検討したり、おすすめボードゲーム集として人に送ったりできる、とっても便利な「<strong>商品のマーク機能</strong>」を利用するには、会員登録とログインが必要です。
              </p>
            </div>
            <div class="modal-footer justify-content-center">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">もどる</button>
              <a href="${window.Routes.login_url}" class="btn btn-primary">会員登録/ログイン</a>
            </div>
          </div>
        </div>
      </div>
    `
    document.body.appendChild(modalElement)

    this.constructor.visitorBookmarkModal = new Modal(
      document.getElementById('visitorBookmarkModal'),
      {
        backdrop: true,
        keyboard: true,
      },
    )
  }

  // ブックマーク変更イベントを発火
  dispatchBookmarkChangedEvent() {
    const event = new CustomEvent('bookmark-changed')
    document.dispatchEvent(event)
  }

  handleError(error) {
    if (error.message === 'Failed to update Bookmark state') {
      alert('サーバ側でのマーク操作ができませんでした。')
    } else {
      // 設定トグルはうまくいったとして、それ以降の問題はリロードで対応する
      alert(
        'サーバ側でのマーク操作後、何か問題が発生しましたので画面を再読込します。',
      )
      window.location.reload()
    }
  }

  disableButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = true
      this.buttonTarget.classList.add('opacity-50', 'cursor-not-allowed')
    }
  }

  enableButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = false
      this.buttonTarget.classList.remove('opacity-50', 'cursor-not-allowed')
    }
  }
}
