import { Controller } from 'stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static values = {
    itemTitle: String,
    actionUrl: String,
    status: String,
    isMember: Boolean,
  }

  openModal(event) {
    event.preventDefault()
    const modalContent = this.generateModalContent()
    const modal = this.createModal(modalContent)
    modal.show()
  }

  generateModalContent() {
    const actionUrl = this.actionUrlValue

    const { head, describe, method, buttonStyle, buttonText } =
      this.getModalConfig()

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    // formではmethod=deleteが指定できないのでRailsの特殊ルーティング用の input hiddenを埋め込む
    const additionalInputs =
      method === 'post'
        ? ''
        : `<input type="hidden" name="_method" value="${method}">`

    return `
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">${head}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ${describe}
            <div class="text-center">
              <a href="${window.Routes.restock_alerts_url}" class="btn btn-outline-linkage" data-turbolinks="false">
                再入荷通知のくわしい案内
              </a>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <form method="post" action="${actionUrl}">
              ${additionalInputs}
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">もどる</button>
              <button type="submit" class="btn ${buttonStyle}">${buttonText}</button>
              <input type="hidden" name="authenticity_token" value="${csrfToken}">
            </form>
          </div>
        </div>
      </div>
    `
  }

  getModalConfig() {
    const status = this.statusValue
    const isMember = this.isMemberValue
    const itemTitle = this.itemTitleValue

    const config = {
      'opt_in_restock': {
        head: '再入荷通知を登録',
        describe: `<p>品切中の『${itemTitle}』が再入荷したときに通知する、会員限定のサービスです。</p>`,
        method: 'post',
        buttonStyle: 'btn-primary',
        buttonText: isMember ? '登録する' : '会員ログイン/登録',
      },
      'awaiting_restock': {
        head: '再入荷通知の登録を解除',
        describe: `<p>商品『${itemTitle}』の再入荷通知に登録しています。解除しますか？</p>`,
        method: 'delete',
        buttonStyle: 'btn-danger',
        buttonText: '解除する',
      },
    }
    return (
      config[status] || {
        head: 'エラー',
        describe: '<p>不明な状態です。</p>',
        method: 'post',
        buttonStyle: 'btn-secondary',
        buttonText: 'OK',
      }
    )
  }

  createModal(content) {
    const modal = document.createElement('div')
    modal.classList.add('modal')
    modal.innerHTML = content
    modal.addEventListener('click', this.handleModalClick.bind(this))
    document.body.appendChild(modal)
    return new Modal(modal)
  }

  handleModalClick(event) {
    if (
      event.target === event.currentTarget ||
      event.target.getAttribute('data-bs-dismiss') === 'modal'
    ) {
      this.closeModal(event.currentTarget)
    }
  }

  closeModal(modal) {
    modal.classList.remove('show')
    setTimeout(() => {
      document.body.removeChild(modal)
    }, 300)
  }
}
